<template>
  <div id="home">

    <div id="mv" ref='vantaRef'>
      <h1><img src="@/assets/logo_motoin.png" alt="ORDER JAPAN" id="logo"></h1>
    </div>

    <div id="maincopy">
      <svg xmlns="http://www.w3.org/2000/svg" width="1233" height="352" viewBox="0 0 1233 352">
        <g id="img_maincopy" transform="translate(74 -559)">
          <text transform="translate(-73 856)" stroke="#000" stroke-width="1" font-size="189" font-family="NotoSansJP-Black, Noto Sans JP" font-weight="800" letter-spacing="-0.037em"><tspan id="t3" x="0" y="0">PERSPECTIVE</tspan></text>
          <text transform="translate(446 699)" stroke="#000" stroke-width="1" font-size="120" font-family="NotoSansJP-Black, Noto Sans JP" font-weight="800" letter-spacing="-0.05em"><tspan id="t2" x="0" y="0">YOUR</tspan></text>
          <text transform="translate(-62 699)" stroke="#000" stroke-width="1" font-size="120" font-family="NotoSansJP-Black, Noto Sans JP" font-weight="800" letter-spacing="-0.05em"><tspan id="t1" x="0" y="0">CHANGE</tspan></text>
        </g>
      </svg>
    </div>

    <section id="about">
      <img src="@/assets/bg_about.jpg" alt="" class="bg">
      <div class="contents">
        <h2>
          <img src="@/assets/bg_maincopy.svg" alt="">
          <div>視点が変われば、<br><strong>始点</strong>も変わる。</div>
        </h2>
        <div id="vision" class="right">
          <h3 class="appr-bottom"><span>VISION</span>見たことのない物<br class="for-sp">だけを創る</h3>
          <p class="appr-bottom">現代社会は進化と変化の時代と言われています。<br>
          AIの発展と共に新しい技術やアイデアが次々と登場し、それに対応するために人々は常に早いスピードで進化を求め続けています。<br>
          技術進化が5年先、10年先の私たちの暮らしにどのような変化を与えるのか想像できないほど絶えず風向きが変化する中。<br>
          私たちオーダー・ジャパン株式会社は、客観的かつユーザー視点から真価をリアルタイムに見極め、
          数年後の社会に根付く体験と価値の創造を、スピード感を持って挑戦し続けます。
          </p>
        </div>
        <div id="representative" class="appr-bottom">
          <img src="@/assets/pic_masahiko-sugimoto.jpg" alt="代表取締役 / CTO 杉本 正彦"><br>
          <div class="name">
            杉本 正彦 / CEO
          </div>
        </div>
      </div>
    </section>

    <section id="spilits">
      <img src="@/assets/bg_spilit.jpg" alt="" class="bg">
      <div class="contents">
        <h2 class="appr-bottom">OJ SPILITS
          <span>オーダー・ジャパンが大切にする3つの精神</span>
        </h2>
        <h3 class="appr-bottom">GO CHALLENGE
          <span>社会への挑戦であること</span>
        </h3>
        <h3 class="appr-bottom">GO COLLABORATION
          <span>コラボレーションによって乗算効果を産む</span>
        </h3>
        <h3 class="appr-bottom">GO SPEEDY
          <span>スピードは最大の武器である</span>
        </h3>
      </div>
    </section>

    <section id="services">
      <div class="contents">
        <h2 class="appr-bottom">SERVICE</h2>
      </div>

        <div class="service">
          <div class="contents">
            <div class="about ">
              <img src="@/assets/bg_service.png" alt="" class="bg">
              <h3 class="appr-bottom">企画開発 / プロダクト開発</h3>
              <p class="appr-bottom">社会、ビジネス、それらを支えるテクノロジーも常に予測不可能なほど変化する中で顧客のニーズやトレンドを把握し、独自のアイデアを取り入れた新しいサービスやプロダクトを開発しています。
                AIやIoT技術を活用したキャッシュレス駐車サービスなど革新的なサービスやプロダクトを開発することに力を入れています。</p>
              </div>
            <div class="works">
              <figure class="appr-bottom">
                <div class="pic">
                  <img src="@/assets/pic_service-rakup.jpg" alt="" class="scale-down">
                  <div class="link">
                    <router-link to="/raku-p" class="btn">MORE</router-link>
                    <a href="https://raku-p.jp/" class="btn" target="_blank">WEB SITE</a>
                  </div>
                </div>
                <figcaption>
                  <h4>AIキャッシュレス駐車場</h4>
                  <h5>Raku-P / Raku-P Light</h5>
                  <p>車のナンバープレートをAIを用いて認識し、車の入庫・出庫をするだけで、クレジット情報を含む会員情報から自動で精算が完結する新しいキャッシュレス駐車場サービスです。</p>
                  <ul class="tags">
                    <li>共同事業</li>
                    <li>システム</li>
                    <li>IOT</li>
                    <li>AI</li>
                    <li>アプリ</li>
                    <li>DX</li>
                    <li>WEB</li>
                  </ul>
                </figcaption>
              </figure>
              <figure class="appr-bottom">
                <div class="pic"><img src="@/assets/pic_service-athome.jpg" alt="" class="scale-down"></div>
                <figcaption>
                  <h4>家庭用情報ハブ</h4>
                  <h5>@HOME</h5>
                  <p>家庭向け情報端末です。Googleカレンダーから家族の予定表を集約し、連絡メモ、週間天気、ゴミ出し情報、バスの到着情報、室温、湿度等の様々な情報が表示ができます。家庭以外にもホテル、病院エントランスにもご活用いただけます。</p>
                  <ul class="tags">
                    <li>システム</li>
                    <li>IOT</li>
                    <li>DX</li>
                    <li>アプリ</li>
                  </ul>
                </figcaption>
              </figure>
            </div>
          </div>

        </div>

        <div class="service">
          <div class="contents">
            <div class="about ">
              <img src="@/assets/bg_service_02.png" alt="" class="bg">
              <h3 class="appr-bottom">サービスブランディング</h3>
              <p class="appr-bottom">IT技術やIoT技術を活用し、サービス価値の再定義とサービスブランディングを行っています。現代の消費者は、単なる商品やサービスだけでなく、そこから得られる体験や感情に価値を求めています。<br>
                私たちは、顧客が求める体験や感情に着目し、サービス価値を再定義することで、ユーザビリティやUXを最大限に考慮したサービスを提供しています。</p>
              </div>
              <div class="works">
              <figure class="appr-bottom">
                <div class="pic">
                  <img src="@/assets/pic_service-kalmol.jpg" alt="" class="scale-down">
                  <div class="link">
                    <a href="https://kalmol.com/" class="btn" target="_blank">WEB SITE</a>
                  </div>
                </div>
                <figcaption>
                  <h4>特化型ECモール</h4>
                  <h5>KALMOL</h5>
                  <p>EC活用ができていない、できない、地元に密着したローカル店舗の味や魅力を全国に発信、お届けできる特化型ECサービスです。<br>
            店舗側は金額の設定、受注後の発送だけで難しいことは一切不要ないので、特別な負荷なく販路拡大が可能です。</p>
                  <ul class="tags">
                    <li>共同事業</li>
                    <li>サービス開発</li>
                    <li>システム</li>
                    <li>WEB</li>
                  </ul>
                </figcaption>
              </figure>

              <figure class="appr-bottom">
                <div class="pic"><img src="@/assets/pic_service-morote.jpg" alt="" class="scale-down"></div>
                <figcaption>
                  <h4>ソーシャルギフトサービス</h4>
                  <h5>もろーて</h5>
                  <p>贈る側も受け取る側も、もっと気軽にギフトを贈れるようにと誕生したサービスです。贈る側は動画を見ながらワンタップで商品を選び、簡単に贈ることができ、受け取る側は届いたメールから住所を入力するだけで、ご自宅等に商品が配送されます。</p>
                  <ul class="tags">
                    <li>共同事業</li>
                    <li>サービス開発</li>
                    <li>システム</li>
                    <li>DX</li>
                    <li>WEB</li>
                  </ul>
                </figcaption>
              </figure>

              <figure class="appr-bottom">
              <div class="pic"><img src="@/assets/pic_service-japangift.jpg" alt="" class="scale-down"></div>
              <figcaption>
                <h4>KOLを利用したギフト特化販売チャネル</h4>
                <h5>JapanGift</h5>
                <p>中国を中心とした、日本国内の商品をギフトとして販売するチャネルです。母の日やクリスマスなどのイベントのタイミングで、KOLからWeiboやWeChatを通してギフトの宣伝を行います。</p>
                <ul class="tags">
                  <li>共同事業</li>
                  <li>サービス開発</li>
                  <li>システム</li>
                  <li>WEB</li>
                </ul>
              </figcaption>
              </figure>

            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/css'
import TOPO from 'vanta/dist/vanta.topology.min'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

export default {
  components: {
    // Swiper,
    // SwiperSlide
  },
  name: 'HomeView',
  data: function () {
    return {
      ctx: {},
      main: {}
    }
  },
  mounted () {
    document.body.classList.add('top')
    // main visual effect
    this.vantaEffect = TOPO({
      el: this.$refs.vantaRef,
      scale: 1.00,
      scaleMobile: 1.00,
      backgroundColor: '#ECEBDE',
      color: '#ff9999'
    })

    this.$nextTick(() => {
      this.ctx = gsap.context((self) => {
        gsap.fromTo('#about h2 img', {
          opacity: 0,
          x: '-15%',
          y: '-35%',
          rotation: -135
        }, {
          opacity: 1,
          rotation: 20,
          scrollTrigger: {
            markers: false,
            trigger: '#maincopy',
            start: 'top center',
            scrub: 2
          }
        })
        /* ------------------------------ works section ----------------------------- */
        // ScrollTrigger.create({
        //   trigger: '.pin',
        //   pin: true,
        //   start: 'top top',
        //   end: 'bottom bottom',
        //   pinSpacing: false,
        //   markers: true
        // })

        /* -------------------------------------------------------------------------- */
        gsap.utils.toArray('.bg').forEach(wrap => {
          // const y = wrap.getAttribute('data-y') || -300
          gsap.fromTo(wrap, {
            y: '0'
          }, {
            y: '-300',
            scrollTrigger: {
              // markers: true,
              // pin: true,
              trigger: wrap,
              end: 'bottom top',
              scrub: 0.5
            }
          })
          // this.gsapList.push(gsapobj)
        })

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: '#about',
            scrub: 0.5,
            end: '-=300'
            // markers: true
          }
        })
        // main copy animation
        tl.fromTo('#t1', { attr: { x: -50 } }, { attr: { x: 0 }, strokeDashoffset: 0, duration: 1 })
        tl.to('#t1', { fillOpacity: 1, duration: 0.4 }, '>-0.1')
        tl.fromTo('#t2', { attr: { x: -50 } }, { attr: { x: 0 }, strokeDashoffset: 0, duration: 1 }, '>-0.9')
        tl.to('#t2', { fillOpacity: 1, duration: 0.4 }, '>-0.1')
        tl.fromTo('#t3', { attr: { x: -50 } }, { attr: { x: 0 }, strokeDashoffset: 0, duration: 1 }, '>-0.1')
        tl.to('#t3', { fillOpacity: 1, duration: 0.4 }, '>-0.1')
        // this.gsapList.push(tl)

        gsap.utils.toArray('.appr-bottom').forEach((section, index) => {
          gsap.fromTo(section, {
            filter: 'blur(10px)',
            y: 100,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            filter: 'blur(0px)',
            scrollTrigger: {
              // markers: true,
              start: 'top bottom',
              end: 'center center+=200',
              scrub: 0.3,
              trigger: section
            }
          })
        })

        gsap.utils.toArray('.scale-down').forEach((section, index) => {
          gsap.fromTo(section, {
            scale: 1.4
          }, {
            scale: 1,
            scrollTrigger: {
              start: 'top bottom',
              end: 'center center',
              scrub: 0.3,
              trigger: section
            }
          })
        })
      }, this.main.value)
    })
  },
  deactivated () {
    location.reload()
    console.info('sss')
  },
  unmounted () {
    document.body.classList.remove('top')
    this.ctx.revert()

    if (this.vantaEffect) {
      this.vantaEffect.destroy()
    }
  },
  methods: {
    setVanta () {
      if (window.VANTA) {
        window.VANTA.TOPOLOGY({
          el: '#mv',
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.00,
          minWidth: 200.00,
          scale: 1.00,
          scaleMobile: 1.00,
          backgroundColor: '#ECEBDE',
          color: '#ff9999'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  h2{
    font-size: 70px;
    margin: 180px 0;
    line-height: 1.2em;
    font-weight: bold;
    position: relative;
    @include mq(sp){
      margin-bottom: 60px;
      font-size: 45px;
    }
  }

  #mv{
    height: 100vh;
    // max-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // >*{
    //   transform: translateY(-50px);
    // }
    #logo{
      width: 17vw;
      max-width: 220px;
      min-width: 180px;
      position: relative;
      transform: translateY(-20%);
    }
    .video{
      position: absolute;
      object-fit: contain;
    }
    &:after{
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba($bg-Color,1) 7%, rgba($bg-Color,0) 100%);
      height: 100px;
      z-index: 0;
    }

  }

  #maincopy{
    overflow: hidden;
    position: absolute;
    z-index: 2;
    left: 0;
    // max-width: 100%;
    width: 100%;
    transform: translateY(-70%);
    svg{
      tspan{
        position: relative;
        left: -100px;
        transform: scale(1.02);
      }
      fill-opacity: 0;
      stroke-dasharray: 700;
      stroke-dashoffset: 700;
      width: 100%;
      max-width: auto;
    }
  }

  #about{
    position: relative;
    min-height: 1360px;
    padding-top: 125px;
    text-align: left;

    h2{
      text-align: left;
      margin-bottom: 150px;
      white-space: nowrap;
      @include mq(sp){
        font-size: 42px;
      }
      img{
        position: absolute;
        user-select: none;
        @include mq(sp){
          transform: scale(1.3);
        }
        // transform: translateX(-10%) translateY(-35%) rotateY(360deg);
      }
      strong{
        color: $oj-red;
      }
    }
    .bg{
      position: absolute;
      left: 0;
      right: auto;
      mix-blend-mode:darken;
      z-index: 0;
      transform: translateY(-10%);
      user-select: none;
      @include mq(sp){
        right: 0;
      }
    }

    #vision{
      max-width: 600px;
      margin: 250px 0 -40px auto;
      position: relative;
      z-index: 1;
      p{
        line-height: 3em;
        font-size: 1.1em;
      }
      @include mq(sp){
        margin-top: 0;
        margin-bottom: 60px;
        p{
          font-size: 1em;
          line-height: 2.5em;
        }
      }
    }

    #representative{
      position: relative;
      z-index: 1;
      max-width: 215px;
      display: flex;
      flex-direction: column;
      @include mq(sp){
          max-width: 50%;
          // transform: scale(.6);
        }
      // align-items: center;
      &:before{
        content: url(@/assets/img_sign.png);
        position: absolute;
        left: 65%;
        bottom: 0;
        transform: scale(.7);
        transform-origin: left bottom;
        @include mq(sp){
          transform: scale(.6);
        }
      }
      .name{
        // writing-mode: vertical-rl;
        white-space: nowrap;
        font-size: 15px;
        padding-left: 0.5em;
        // letter-spacing: .1em;
        // display: block;
        // margin: auto;
      }
      .position{
        font-size: 0.5em;
      }
    }
  }

  #spilits{
    position: relative;
    // min-height: 1540px;
    .bg{
      // content: url();
      position: absolute;
      left: auto;
      right: 0;
      mix-blend-mode:darken;
      z-index: 0;
      top: -200px;
      user-select: none;
      // width: 30%;
      @include mq(sp){
        // width: 70%;
        left: 35%;
        right: auto;
        top: 0;
      }
    }
    h2{
      line-height: .8em;
    }
    h3{
      font-size: 50px;
      position: relative;
      margin: 70px 0;
      line-height: 1em;
      display: grid;
      grid-template-columns: 160px 1fr;
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      grid-area: 1 / 2 / 2 / 3;

      @include mq(sp){
        margin: 50px 0;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        font-size: 38px;
        grid-area: 1 / 1 / 2 / 3;
      }
      span{
        font-size: 0.4em;
        color: #6b6a5e;
        grid-area: 2 / 2 / 3 / 3;
        @include mq(sp){
          grid-area: 3 / 1 / 4 / 2;
        }
      }
      &:before{
        content: url(@/assets/ico_challenge.svg);
        grid-area: 1 / 1 / 3 / 2;
        transform: scale(.9);
        // position: absolute;
        // transform: translateX(-5vh);
        // right: 100%;
        // top: 0;
        // float: left;
        @include mq(sp){
          grid-area: 1 / 1 / 2 / 3;
          // position: initial;
          margin-bottom: 20px;
          transform-origin: left;
          transform: scale(.8);
        }
      }
      &:nth-of-type(2){
        &:before{
          content: url(@/assets/ico_collaboration.svg);
        }
      }
      &:nth-of-type(3){
        &:before{
          content: url(@/assets/ico_user-central.svg);
        }
      }
    }
  }

  #services{
    .bg{
      position: absolute;
      right: 90%;
      top: 30%;
      max-width: inherit;
      // mix-blend-mode:darken;
      // transform-origin: center right;
      // transform: translateX(-80%);
      transform: scale(1.3);
      z-index: 0;
      user-select: none;
      @include mq(sp){
        right: 70%;
        top: 0;
        .bg{
          transform: scale(1);
        }
      }
    }

    h3{
      // background: #333;
      border: 2px solid #000;
      // color: #fff;
      // display: inline-block;
      // backdrop-filter: blur(6px);
      padding: 2.2em 1.4em;
      font-size: 22px;
      margin-bottom: 3em;
      position: relative;
      text-align: center;
      @include mq(sp){
        font-size: 22px;
        width: 100%;
        padding: 0.2em 0;
      }
    }

    .service{
      position: relative;
      margin-bottom: 200px;

      @include mq(sp){
        margin-bottom: 100px;
      }
      >div.contents{
        display: flex;
        align-items: start;
        justify-content: space-between;
        gap: 8vw;
        @include mq(sp){
          flex-direction: column;
        }
      }

      .about{
        width: 40%;
        position: sticky;
        top: 30%;
        z-index: 1;
        @include mq(sp){
          width: 100%;
          position: inherit;
        }
      }
      .works{
        flex: 1;
        padding-top: 500px;
        @include mq(sp){
          padding-top: 50px;
        }
        // margin-left: auto;
      }

    }

    figure{
        margin: 0;
        margin-bottom: 150px;
        @include mq(sp){
          margin-bottom: 80px;
        }
        .pic{
          position: relative;
          margin-bottom: 30px;
          overflow: hidden;
          line-height: 0;
          box-shadow: 10px 40px 60px #00000022;
          .link{
            position: absolute;
            right: 20px;
            bottom: 20px;
            .btn{
              margin-left: 5px;
            }
            @include mq(sp){
              right: 10px;
              bottom: 10px;
              .btn{
                padding: .5em 1em;
                margin-left: 0;
              }
            }
          }
        }

        p{
          width: 85%;
          font-size: 0.9em;
          margin: 2em auto;
          @include mq(sp){
            width: 90%;
          }
        }
        h4{
          font-size: 17px;
        }
        h5{
          opacity: .6;
        }
        h4,h5{
          text-align: center;
          margin: 0;
          line-height: 2em;
        }
      }
    .swiper{
      margin: 70px 0 100px 0;
      cursor: ew-resize;
      // overflow: visible;
      @include mq (sp){
        margin-bottom: 50px;
      };

    }
  }

  .tags{
    list-style: none;
    // color: #66655c;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    li{
      display: inline-block;
      font-size: 13px;
      background-color: #E2E1D3;
      padding: .4em 1em;
      line-height: 1em;
      // border-radius: 3px;
      // &:first-child:before{
      //   content: "|";
      //   font-size: 2px;
      //   padding-right: 10px;
      //   color: #aaa;
      //   display: inline-block;
      // }
      // &::after{
      //   content: "|";
      //   font-size: 2px;
      //   padding-left: 10px;
      //   color: #aaa;
      //   display: inline-block;
      // }
    }
  }
</style>
