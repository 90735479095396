import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'ORDER JAPAN / オーダー・ジャパン株式会社',
      metaTags: [
        {
          name: 'description',
          content: 'オーダージャパン・株式会社は常に変わりゆく技術進化に臨機応変に対応し、顧客を巻き込むスクラムチーム一丸となってスピード感のあるプロジェクトを推進します。'
        },
        {
          property: 'og:description',
          content: 'オーダージャパン・株式会社は常に変わりゆく技術進化に臨機応変に対応し、顧客を巻き込むスクラムチーム一丸となってスピード感のあるプロジェクトを推進します。'
        }
      ]
    }
  },
  {
    path: '/raku-p',
    name: 'service-rakup',
    meta: {
      title: 'AIキャッシュレス駐車場(Raku-P/Raku-P Light)｜ORDER JAPAN',
      metaTags: [
        {
          name: 'description',
          content: 'カメラによる車番認証AIを活用したキャッシュレス駐車場の実現と、初期費用を最小に抑えた空きスペースの有効活用ソリューションを提供します。'
        },
        {
          property: 'og:description',
          content: 'カメラによる車番認証AIを活用したキャッシュレス駐車場の実現と、初期費用を最小に抑えた空きスペースの有効活用ソリューションを提供します。'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "rakp" */ '../views/ServiceRakup.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: 'お問い合わせフォーム｜ORDER JAPAN',
      metaTags: [
        {
          name: 'description',
          content: 'オーダー・ジャパンの製品・サービスに関するお問い合わせ、新規事業のご相談などお気軽にご相談ください。'
        },
        {
          property: 'og:description',
          content: 'オーダー・ジャパンの製品・サービスに関するお問い合わせ、新規事業のご相談などお気軽にご相談ください。'
        }
      ]
    },
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {
      top: 0
    }
  }
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
