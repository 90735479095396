<template>
  <header>
    <router-link to="/" class="logo"><img src="@/assets/logo_02.svg" alt="ORDER JAPAN / オーダー・ジャパン株式会社"></router-link>
  </header>
  <main id="top">
  <router-view ></router-view>
  </main>
  <footer>
    <img src="@/assets/bg_footer.jpg" alt="" class="">
    <a href="#top" id="totop"><img src="@/assets/ico_totop.svg" alt="ページトップに戻る" loading="lazy"></a>
    <div class="contents">
      <div>
        <img src="@/assets/logo.svg" alt="" loading="">
        <small>© ORDER JAPAN Inc.</small>
        <!-- <a href="#">Privacy Policy</a> -->
      </div>
      <div class="">
        <h3>一緒に新しい体験・未来を創造しませんか。</h3>
        <button @click="showModal" class="btn">CONTACT FORM</button>
        <dl class="company-info">
          <dt>社名</dt>
          <dd>オーダー・ジャパン株式会社</dd>
          <dt>設立</dt>
          <dd>2013年7月1日</dd>
          <dt>資本金</dt>
          <dd>2,000万円</dd>
          <dt>代表取締役</dt>
          <dd>杉本 正彦</dd>
          <dt>事業内容</dt>
          <dd>企画開発、プロダクト開発、サービス開発、DX支援</dd>
          <dt>住所</dt>
          <dd>〒730-0015 広島県広島市中区橋本町7-14 橋本町BLD 4F</dd>
        </dl>
      </div>
    </div>
  </footer>
  <Transition>
    <div id="contactWindow" v-show="openModal">
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfmxb7DWVqdPmuyew-x8nCM0Us8DixA-HdHguzGXGuDh8gZaw/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
      <button class="close" @click="hideModal">×</button>
    </div>
  </Transition>
</template>
<script>
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  data () {
    return {
      openModal: false,
      inited: false
    }
  },
  methods: {
    showModal (item) {
      this.openModal = true
      document.body.classList.add('modal-open')
    },
    hideModal (item) {
      this.openModal = false
      document.body.classList.remove('modal-open')
    }
  },
  mounted () {
    ScrollTrigger.create({
      // markers: true,
      trigger: document.body,
      start: '800 top',
      end: document.body.innerHeight,
      toggleClass: { className: 'scrolled', targets: 'body' }
    })
  }

}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;600;900&display=swap');

$oj-red: #CB2927;

*{
  box-sizing: border-box;
}
html {
  // scroll-behavior: smooth;
}
body{
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: #ECEBDE;
  background-image: url(@/assets/bg.png);
  letter-spacing: .05em;
  #totop,
  &.top header{
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

  &.scrolled{
    #totop, header{
      opacity: 1;
      bottom: 20px;
      transition: all 0.3s ease-in-out;
    }
    header{
      top: 0;
    }
  }
  &.modal-open {
    overflow: hidden;
  }
}
h2{
  font-size: 50px;
  font-weight: 900;
  margin: 0;
}
h3{
  font-size: 40px;
  display: flex;
  flex-direction: column;
  @include mq(sp){
    font-size: 30px;
  }
  span{
    font-size: 0.5em;
  }
}
a{
  // text-decoration: none;
  color: inherit;
}
img{
  max-width: 100%;
}
main{
  section{
    margin: 0px 0;
    overflow-x: clip;
    &:first-of-type{
      margin-top: 0;
    }
    h2{
      span{
        font-size: 0.3em;
        line-height: 3em;
        display: block;
      }
      @include mq(sp){
        text-align: center;
      }
    }
    p{
      line-height: 2.4em;
      @include mq(sp){
        line-height: 2em;
      }
    }
  }
}
.contents{
  max-width: 1100px;
  width: 95%;
  margin: auto;
  @include mq(sp){
    max-width: 90%;
  }
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #111;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

header{
  position: fixed;
  top: -10px;
  left: 0;
  z-index: 10;
  .logo{
    display: inline-block;
    margin-left: 20px;
    margin-top: 20px;
    width: 65px;
  }
}
footer{
    margin-top: 30px;
    padding: 0 0 150px 0;
    position: relative;
    overflow-x: hidden;
    @include mq(sp){
      padding-bottom: 50px;
    }
    > img{
      z-index: 1;
      // background: ;
      // background-repeat: no-repeat;
      // background-position: bottom left;
      position: absolute;
      left: 0;
      // width: 100%;
      bottom: 0;
      // top: 0;
      mix-blend-mode:darken;
      user-select: none;
      line-height: 0;
    }
    h3{
      font-size: 30px;
    }
    // .mail{
    //   font-size: 55px;
    //   font-weight: bold;
    //   text-decoration: none;
    //   color: inherit;
    //   margin-bottom: 20px;
    //   span{
    //     color: $oj-red;
    //   }
    //   &:hover{
    //     text-decoration: underline;
    //   }
    //   @include mq(sp){
    //     font-size: 38px;
    //     line-height: 1.2em;
    //     span{
    //       display: block;
    //     }
    //   }
    // }
    .contents{
        padding-top: 150px;
        border-top: 1px solid #00000015;
        display: flex;
        gap: 8vw;
        z-index: 1;
        position: relative;
        justify-content: center;
        @include mq(sp){
          padding-top: 50px;
          flex-direction: column-reverse;
          align-items: center;
          gap: 10vh;
        }
        & > div{
          flex-direction: column;
          display: flex;
          &:first-child{
            font-size: 13px;
            text-align: center;
            line-height: 1.6em;
            img{
              width: 140px;
              margin-bottom: 60px;
              @include mq(sp){
                width: 28vw;
              }
            }
          }
          &:last-child{
            flex: 1;
            align-items: flex-start;
          }
        }

        dl{
          font-size: 14px;
          display: flex;
          flex-wrap: wrap;
          margin: 80px 0 0 0;
          dt,dd{
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 25px;
          }
          dt{
            width: 25%;
          }
          dd{
            width: 75%;
          }
        }

        .btn{
          font-size: 21px;
          font-weight: bold;
          width: 500px;
          padding-top: 0.8em;
          padding-bottom: 0.8em;
          background: #000;
          color: #fff;
          border: none;
          background: linear-gradient(348deg, rgba(187,29,29,1) 6%, rgba(0,0,0,1) 7%);
          @include mq(sp){
                font-size: 20px;
                width: 100%;
              }
        }
    }
  }

  #totop{
    position: fixed;
    right: 0px;
    opacity: 0;
    right: 25px;
    bottom: 0px;
    z-index: 10;
  }
  .btn{
    display: inline-block;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 30px;
    letter-spacing: .04em;
    line-height: 1;
    text-align: center;
    padding: .6em 1.4em;
    color: #000;
    text-decoration: none;
    font-weight: 300;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    transition: all .3s ease;
    &:hover{
      box-shadow: 0 0 10px #00000022;
      background-color: #ffffffdd;
    }
    @include mq(sp){
      display: block;
      width: 100%;
      padding: .9em 1em;
      margin: 10px 0;
    }
    &[target*="_blank"]{
      &:after{
        content: url(@/assets/ico_external.svg);
        margin-left: 8px;
      }
    }
  }

  #contactWindow{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    z-index: 50;
    height: 100vh;
    overflow: hidden;
    iframe{
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
    &:before{
      content: "";
      background: #ECEBDEf1;
      // backdrop-filter: blur(20px);
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .close{
      position: absolute;
      right: 0;
      top: 0;
      border: none;
      background: transparent;
      font-size: 50px;
      font-weight: normal;
      width: 50px;
      height: 50px;
      right: 10px;
      z-index: 1;
      cursor: pointer;
    }
  }

  .v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
